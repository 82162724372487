import React from "react";

import { Container, PageWidth, Section } from "@util/standard";
import EventCard from "./eventCard";
import { EventsInRegions } from "@state/types";
import { Maybe, SanityEvent, SanityRegion } from "@graphql-types";
import { sortEventsByDate } from "@util/helper";

type Props =
  | {
      data?: DefaultDataProps;
      algoliaHits: AlgoliaProps;
      featuredRegion?: Maybe<SanityRegion> | undefined;
      sortValue: SortValue;
    }
  | {
      data: DefaultDataProps;
      algoliaHits?: AlgoliaProps;
      featuredRegion: Maybe<SanityRegion> | undefined;
      sortValue: SortValue;
    };

type DefaultDataProps = EventsInRegions[];

type AlgoliaProps = Maybe<SanityEvent[]> | undefined;

type SortValue = "latest" | "earliest";
/**
 * Renders events in their regions
 *
 */
export default function Events({ data, algoliaHits, featuredRegion, sortValue }: Props) {
  if (data == null && algoliaHits == null) return null;

  const featuredRegionEvents =
    featuredRegion &&
    data &&
    data.filter(region => {
      if (region == null) return null;
      return region.region._id === featuredRegion?._id;
    });

  return (
    <Section aria-labelledby="events" marginOverride="0px 0 0 0">
      <PageWidth>
        <Container flexDirection="column" rowGap="80px">
          {featuredRegionEvents && (
            <RegionEventsList data={featuredRegionEvents} hideTitle invert sortValue={sortValue} />
          )}
          {data && <RegionEventsList data={data} sortValue={sortValue} />}
          {algoliaHits && <EventsHits data={algoliaHits} sortValue={sortValue} />}
        </Container>
      </PageWidth>
    </Section>
  );
}

const todayTime = new Date().setHours(0, 0, 0, 0);

const RegionEventsList = ({
  data,
  invert,
  hideTitle,
  sortValue,
}: {
  data: EventsInRegions[];
  invert?: boolean;
  hideTitle?: boolean;
  sortValue: SortValue;
}) => {
  if (data == null) return null;

  return (
    <Container flexDirection="column" rowGap="80px" width="100%">
      {data?.length === 0 && <h3>No upcoming Events</h3>}
      {data?.length > 0 &&
        data.map((eventInRegion, index) => {
          if (eventInRegion == null) return null;
          const { events, region } = eventInRegion;

          const currentEvents = events.filter(
            event => new Date(event.dateTime).setHours(0, 0, 0, 0) >= todayTime,
          );

          if (!Boolean(currentEvents.length)) return null;

          const sortCurrentEvents = sortEventsByDate(currentEvents, sortValue);

          return (
            <Container
              key={index}
              flexDirection="column"
              margin="40px 0 0 0"
              mobileMargin="60px 0 0 0"
              rowGap="20px"
            >
              {!hideTitle && <h2 id="heading">{region.title}</h2>}
              {sortCurrentEvents.map(event => {
                if (event == null) return null;
                return <EventCard data={event} invertColors={invert} key={event._key} />;
              })}
            </Container>
          );
        })}
    </Container>
  );
};

const EventsHits = ({ data, sortValue }: { data: SanityEvent[]; sortValue: SortValue }) => {
  const currentEvents = data.filter(
    event => new Date(event.dateTime).setHours(0, 0, 0, 0) >= todayTime,
  );

  const sortCurrentEvents = sortEventsByDate(currentEvents, sortValue);

  return (
    <Container
      flexDirection="column"
      rowGap="80px"
      margin="40px 0 0 0"
      mobileMargin="60px 0 0 0"
      width="100%"
    >
      {sortCurrentEvents?.length === 0 && <h3>No upcoming Events</h3>}
      <Container flexDirection="column" rowGap="20px">
        {sortCurrentEvents.map((event, index) => {
          if (event == null) return null;

          return <EventCard key={index} data={event} />;
        })}
      </Container>
    </Container>
  );
};
