import React, { ChangeEvent, useEffect, useState } from "react";
import { InstantSearch, Index, connectHits, Configure } from "react-instantsearch-dom";

import { PageWidth, Section } from "@util/standard";
import { algoliaSearchClient } from "@util/helper";
import { useStore } from "@state/store";
import { Maybe, SanityEvent, SanityRegion } from "@graphql-types";

import { EventsInRegions } from "@state/types";
import Events from "@components/events";
import EventsFilters from "./eventsFilters";
import { DropdownContainer } from "@styles/formStyles";
import styled from "styled-components";
import { mediaQuery } from "@util/constants";

interface Props {
  defaultData?: EventsInRegions[];
  featuredRegion?: Maybe<SanityRegion> | undefined;
}

interface HitsProps extends Props {
  hits: SanityEvent[];
}

export default function EventsSearchWrapper({ defaultData, featuredRegion }: Props) {
  const { clearSearchQuery } = useStore();
  const [sortValue, setSortValue] = useState<"latest" | "earliest">("earliest");
  useEffect(() => {
    return () => clearSearchQuery();
  }, []);

  function EventsHits(props: HitsProps) {
    const { hits, defaultData, featuredRegion } = props;
    const { searchQuery, searchFacets } = useStore();

    if (Boolean(searchQuery?.length || Boolean(searchFacets.length))) {
      return <Events algoliaHits={hits} featuredRegion={featuredRegion} sortValue={sortValue} />;
    }

    if (defaultData)
      return <Events data={defaultData} featuredRegion={featuredRegion} sortValue={sortValue} />;

    return null;
  }

  const CustomEventsSearchHits: React.ComponentClass<any, Props> = connectHits(EventsHits);

  const handleSort = (e: ChangeEvent<HTMLSelectElement>) => {
    if (e.target.value !== "earliest" && e.target.value !== "latest") return;

    setSortValue(e.target.value);
  };

  return (
    <Section marginOverride="0 0 150px 0">
      <InstantSearch indexName="events" searchClient={algoliaSearchClient}>
        <Index indexName="events">
          <Configure hitsPerPage={1000} />
          <EventsFilters />
          <PageWidth>
            <SortWrapper>
              <DropdownContainer defaultValue="earliest" noLabel onChange={e => handleSort(e)}>
                <option value="earliest">Earliest</option>
                <option value="latest">Latest</option>
              </DropdownContainer>
            </SortWrapper>
          </PageWidth>
          <div id="events-hits">
            <CustomEventsSearchHits
              defaultData={defaultData}
              featuredRegion={featuredRegion}
              sortValue={sortValue}
            />
          </div>
        </Index>
      </InstantSearch>
    </Section>
  );
}

const SortWrapper = styled.div`
  max-width: 200px;

  select {
    margin: 0;
  }
  ${mediaQuery.tabletDown} {
    margin-top: 40px;
  }
`;
