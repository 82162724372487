import React from "react";
import { graphql, PageProps } from "gatsby";

import { Query } from "@graphql-types";
import { Blocks, ErrorMsg, Header, SEO } from "@shared";
import { usePageMeta } from "@util/logicHooks";
import { BasicHero } from "@shared";

import { EventsLandingPageContext } from "@state/types";
import EventsSearchWrapper from "@components/events/eventsSearchWrapper";

type PageContext = PageProps & EventsLandingPageContext;
interface Props {
  data: Query;
  pageContext: PageContext;
}

export default function EventsLandingTemplate({
  data: { sanityEventsLanding, sanityHeader },
  pageContext: { eventsInRegions },
}: Props) {
  if (sanityEventsLanding == null)
    return <ErrorMsg data={sanityEventsLanding} msg="Error fetching data for page" />;

  const { slug, _type, pageColour, title, seo, blockContent, ctas, blocks, featuredRegionEvents } =
    sanityEventsLanding;
  usePageMeta(pageColour?.colour?.hex, _type);

  return (
    <div>
      <SEO seoData={seo} slug={slug?.current as string} />
      <Header data={sanityHeader} />
      <BasicHero heading={title} blockContent={blockContent} ctas={ctas} />

      <EventsSearchWrapper defaultData={eventsInRegions} featuredRegion={featuredRegionEvents} />

      {blocks && <Blocks data={blocks} />}
    </div>
  );
}

export const query = graphql`
  query eventsLandingQuery {
    sanityHeader {
      ...sanityHeader
    }
    sanityEventsLanding {
      seo {
        ...sanitySeo
      }
      _type
      title
      blockContent {
        _rawBlockContent
      }
      ctas {
        ...sanityLink
      }
      slug {
        current
      }
      pageColour {
        ...sanityColorPicker
      }
      blocks {
        ...staticPageBlocks
      }
      featuredRegionEvents {
        _id
      }
    }
  }
`;
