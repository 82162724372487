import React from "react";

import { Button } from "@global";
import { Container, PageWidth } from "@util/standard";
import { useToggle } from "@util/hooks";
import { useStore } from "@state/store";
import { CustomRefinementList, CustomClearRefinements } from "@components/search/refinement";
import { useUrlSearchParamForRefinement } from "@util/logicHooks";
import { isBrowser } from "@util/helper";

export default function EventsFilters() {
  const [applyFilters, toggle] = useToggle();
  const { pageColor, searchFacets } = useStore();
  const { searchInput, shouldClear } = useUrlSearchParamForRefinement(toggle);

  const handleClick = () => {
    const resultsContainer = document.getElementById("events-hits");

    if (isBrowser() && resultsContainer) {
      window.scrollTo({ top: resultsContainer.offsetTop - 100, behavior: "smooth" });
    }
    toggle();
  };

  return (
    <Container margin="100px 0 0 0" mobileMargin="50px 0 0 0">
      <PageWidth>
        <Container margin="20px 0 0 0" columnGap="25px" rowGap="10px" isTabletColumn>
          <CustomRefinementList
            indexName="events"
            listTitle="Category"
            operator="or"
            attribute="categories.title"
            applyFilters={applyFilters}
            shouldClear={shouldClear}
          />

          <CustomRefinementList
            indexName="events"
            listTitle="Region"
            operator="or"
            attribute="eventVillages.region.title"
            applyFilters={applyFilters}
            shouldClear={shouldClear}
          />

          <Container width="100px" flexDirection="column">
            <Container margin="0 0 10px 0">
              <Button
                theme="colored"
                colorvalue={pageColor}
                onClick={handleClick}
                aria-label="Apply selected filters"
              >
                Apply
              </Button>
            </Container>
            <CustomClearRefinements />
          </Container>
        </Container>
      </PageWidth>
    </Container>
  );
}
